import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container } from '@ttstr/components';

const EmailSent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="my-5 bg-container">
      <Container>
        <Helmet>
          <title>{t(`NEWSLETTER.EMAIL_SENT.TITLE`)}</title>
        </Helmet>
        <h1 className="text-center mb-4">{t(`NEWSLETTER.EMAIL_SENT.TITLE`)}</h1>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <p className="text-justify text-center">{t('NEWSLETTER.EMAIL_SENT.DESCRIPTION')}</p>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default React.memo(EmailSent);
